import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import _ from 'lodash';
import moment from 'moment';
import { MarginVerticalSection } from '../styled';
import { RequiredLabel, CustomDateTimePicker } from '../common';
import { ErrorMessages } from '../../constants';
import { isValidDate } from '../../utils/utils';

export default function GoLive({ defaultValue, forwardedRef }) {
  const { setValue, errors, clearErrors, control } = useFormContext();

  const [minGoLiveDate] = useState(moment().add(1, 'day').set('second', 0).set('millisecond', 0));

  const isGoLiveDtAfterNow1Day = (goLiveDt) => {
    const now = moment().second(0).millisecond(0);
    return !moment(goLiveDt).isBefore(now.add(1, 'd'));
  };

  return (
    <MarginVerticalSection>
      <RequiredLabel htmlFor="go_live" value="Go-Live Date" forwardedRef={forwardedRef} />
      <Controller
        render={({ onChange, ...props }) => (
          <CustomDateTimePicker
            {...props}
            id="go_live_dt"
            label="If you want to go-live with our service with a specific date, the date should be at least one day after the registration or it will go-live automatically after the approval."
            disablePast
            selectedDate={_.get(props, ['value'])}
            onDateChange={(val) => {
              onChange(val);
              if (!val) {
                clearErrors('go_live_dt');
              }
            }}
            minDate={minGoLiveDate}
            error={errors.go_live_dt}
            parseError={(e) => {
              const type = _.get(e, ['type'], undefined);
              if (!type) {
                return '';
              }
              if (type === 'isValidDate') {
                return ErrorMessages.InvalidDateFormat;
              }
              return ErrorMessages.InvalidGoLiveDt;
            }}
            clearable
            allowKeyboardControl
          />
        )}
        name="go_live_dt"
        onChange={(val) => {
          setValue('go_live_dt', val);
        }}
        control={control}
        rules={{
          validate: {
            isValidDate,
            isGoLiveDtAfterNow1Day,
          },
        }}
        defaultValue={defaultValue}
      />
    </MarginVerticalSection>
  );
}

GoLive.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  forwardedRef: PropTypes.shape(React.createRef()).isRequired,
};
