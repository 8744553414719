import React from 'react';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { Grid, FormControlLabel, Checkbox, FormGroup } from '@material-ui/core';
import { MarginVerticalSection } from '../styled';
import { RequiredLabel } from '../common';

export default function PaidInteroperability() {
  const { setValue, register } = useFormContext();

  return (
    <MarginVerticalSection>
      <RequiredLabel htmlFor="" value="Paid Services: Interoperability" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div style={{ padding: 8 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="paid_gtr"
                    name="paid_gtr"
                    onChange={(e) => {
                      setValue('paid_gtr', e.target.checked);
                    }}
                    forwardedRef={register('paid_gtr', {
                      required: false,
                    })}
                    color="primary"
                  />
                }
                label="Global Travel Rule (GTR)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="paid_code"
                    name="paid_code"
                    onChange={(e) => {
                      setValue('paid_code', e.target.checked);
                    }}
                    forwardedRef={register('paid_code', {
                      required: false,
                    })}
                    color="primary"
                  />
                }
                label="CODE"
              />
            </FormGroup>
          </div>
        </Grid>
      </Grid>
    </MarginVerticalSection>
  );
}
