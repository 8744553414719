import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/react-hooks';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { yellow } from '@material-ui/core/colors';
import * as _ from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import Layout from '../components/layout';
import {
  parseGraphQLErrorMessage,
  transferDataToSubmitArg,
  getCountryCodes,
  scrollToRef,
  navigate,
  decodeJWTPayload,
  getRegistrationType,
  checkJWTExpired,
} from '../utils/utils';
import { GET_VASP_REGISTER, SUBMIT_FORM, VERIFY_VASP_CODE } from '../crud';
import logo from '../images/logo.png';
import bundleProductLogo from '../images/sygna_bundle_logo.png';
import { Operations, ErrorMessages, VaspRegisterType } from '../constants';
import {
  ErrorDialog,
  CustomDialog,
  LoadingButton,
  RequiredLabel,
  ResizeTextField,
  HookFormTextField,
} from '../components/common';
import Bridge from '../components/registration/bridge';
import Hub from '../components/registration/hub';
import EmailProtocol from '../components/registration/email-protocol';
import PaidServices from '../components/registration/paid-services';
import { MarginVerticalSection } from '../components/styled';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormHelperText-root': {
      fontFamily: 'sans-serif',
      fontSize: '16px',
    },
  },
  container: {
    width: '100%',
    paddingRight: '40px',
    paddingLeft: '40px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  headerContainer: {
    paddingLeft: '30px',
    paddingRight: '30px',
    borderBottom: '7px solid #104935',
  },
  logo: {
    display: 'inline-block',
    marginBottom: '6px',
    maxWidth: '165px',
  },
  content: {
    background: 'white',
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  intro: {
    padding: '30px 0 20px',
  },
  introTitle: {
    fontSize: '18px',
    marginBottom: '0',
    color: '#222b45',
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  ctaBlock: {
    padding: '20px 0 30px',
  },
  ctaBlockText: {
    marginTop: '0',
    marginBottom: '20px',
  },
  ctaBtn: {
    position: 'relative',
    display: 'inline-block',
    textDecoration: 'none',
    textTransform: 'capitalize',
    boxShadow: 'none',
    height: 'auto',
    color: 'white',
    backgroundColor: '#104935',
    '&:hover': {
      color: '#c39e37',
      backgroundColor: '#197353',
    },
  },
  formControl: {
    width: '100%',
  },
  buttonProgress: {
    color: yellow[800],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  header: {
    width: '100%',
    padding: '30px 0 0',
  },
  normalFontSize: {
    fontSize: '16px',
  },
}));

function Logo({ className, registrationType }) {
  let imgSrc;
  if (registrationType === VaspRegisterType.Gate || registrationType === VaspRegisterType.Hub) {
    imgSrc = bundleProductLogo;
  } else {
    imgSrc = logo;
  }
  const serviceName = getRegistrationType(registrationType);
  return <img id="logo" className={className} src={imgSrc} alt={serviceName} />;
}
Logo.propTypes = {
  className: PropTypes.string.isRequired,
  registrationType: PropTypes.number,
};
Logo.defaultProps = {
  registrationType: VaspRegisterType.Bridge,
};

export default function Registration({ location }) {
  const token = _.get(location, ['state', 'token'], '');
  if (!token || checkJWTExpired(token)) {
    navigate('/register');
    return null;
  }
  // extract registration type from token
  const tokenPayload = decodeJWTPayload(token);
  // new version use registration_type, but incase there are old valid token
  // so old "type" will also work
  const regType = tokenPayload.registration_type || tokenPayload.type || VaspRegisterType.Bridge;
  const serviceName = getRegistrationType(regType);

  const [informDialogOpen, setInformDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [error, setError] = useState('');

  const [createLoading, setCreateLoading] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);

  const [existedVaspCodeError, setExistedVaspCodeError] = useState(true);

  const [vaspDetail, setVaspDetail] = useState({
    vasp_email: '',
    vasp_name: '',
    operation: '',
    vasp_code_prefix: '',
    country_code: '',
    city_code: '',
    branch_code: '',
    company_id: '',
    vasp_category: '',
    vasp_receive_url: '',
    vasp_fixed_ip: '',
    pem_pubkey: '',
    go_live_dt: '',
    raw_receivers: [],
    notification_receivers: [],
    assets: [],
    raw_assets: [],
    estimated_customer_number: 0,
    estimated_annual_transaction_volume: 0,
    estimated_annual_travel_rule_data_transfer_number: 0,
    paid_gtr: false,
    paid_code: false,
  });
  const [currencies, setCurrencies] = useState(undefined);

  const countries = getCountryCodes();

  const hookFormProps = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const {
    handleSubmit,
    register,
    errors,
    setValue,
    control,
    reset,
    setError: setFormError,
    getValues,
    trigger: formTrigger,
  } = hookFormProps;

  useQuery(GET_VASP_REGISTER, {
    variables: {
      filter_active: true,
      get_currencies: regType === VaspRegisterType.EmailProtocol,
    },
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      if (!_.has(data, 'getVaspRegister')) {
        setVaspDetail({});
        return;
      }

      const vaspRegister = data.getVaspRegister;
      if (_.has(vaspRegister, 'vasp_receive_url')) {
        vaspRegister.vasp_receive_url =
          (vaspRegister.vasp_receive_url || '').replace('https://', '') || null;
      }
      if (_.has(vaspRegister, 'callback_validate_addr_url')) {
        vaspRegister.callback_validate_addr_url =
          (vaspRegister.callback_validate_addr_url || '').replace('https://', '') || null;
      }
      if (_.has(vaspRegister, 'callback_txid_url')) {
        vaspRegister.callback_txid_url =
          (vaspRegister.callback_txid_url || '').replace('https://', '') || null;
      }
      if (_.has(vaspRegister, 'callback_host_url')) {
        vaspRegister.callback_host_url =
          (vaspRegister.callback_host_url || '').replace('https://', '') || null;
      }
      if (_.has(vaspRegister, 'vasp_fixed_ip')) {
        vaspRegister.vasp_fixed_ip = (vaspRegister.vasp_fixed_ip || []).join(',') || null;
      }

      if (_.get(vaspRegister, 'country_code')) {
        vaspRegister.country_code =
          countries.find((e) => e.country_code === vaspRegister.country_code) || null;
      }

      if (_.has(vaspRegister, 'pem_pubkey')) {
        vaspRegister.pem_pubkey =
          (vaspRegister.pem_pubkey || '').replace(/[-]*BEGIN PUBLIC KEY[-\n]*/, '') || null;
        vaspRegister.pem_pubkey =
          (vaspRegister.pem_pubkey || '').replace(/[\n-]*END PUBLIC KEY[-]*/, '') || null;
      }
      vaspRegister.raw_receivers = [];
      vaspRegister.raw_assets = [];

      if (regType !== VaspRegisterType.EmailProtocol) {
        setVaspDetail(vaspRegister);
        reset(vaspRegister);
        return;
      }

      const currencyOptions = data.getCurrencies.map((currency) => ({
        ...currency,
        groupTitle: currency.is_token ? 'token' : '',
      }));

      (vaspRegister.notification_receivers || []).forEach((e) => {
        vaspRegister.raw_receivers.push({ value: e });
      });

      const currencyCache = {};
      (vaspRegister.assets || []).forEach((asset) => {
        let targetCurrency = currencyCache[asset.currency_id];
        if (!targetCurrency) {
          const currency = currencyOptions.find((c) => c.currency_id === asset.currency_id);
          currencyCache[asset.currency_id] = currency;
          targetCurrency = currency;
        }

        vaspRegister.raw_assets.push({
          currency: {
            ...targetCurrency,
          },
          address: asset.address,
          raw_extra_info: (asset.addr_extra_info || []).reduce((arr, cur) => {
            Object.entries(cur).forEach((e) => {
              arr.push({
                key: e[0],
                value: e[1],
              });
            });
            return arr;
          }, []),
        });
      });
      if (vaspRegister.raw_assets.length === 0) {
        vaspRegister.raw_assets.push({
          currency: {
            groupTitle: '',
            currency_id: 'sygna:0x80000000',
            currency_symbol: 'BTC',
            currency_name: 'Bitcoin',
            is_active: true,
            is_token: false,
            addr_extra_info: [],
          },
          address: '',
          raw_extra_info: [],
        });
      }

      setVaspDetail(vaspRegister);
      setCurrencies(currencyOptions);
      reset(vaspRegister);
    },
    onError: (e) => {
      setErrorDialogOpen(true);
      setError(parseGraphQLErrorMessage(e));
    },
    fetchPolicy: 'no-cache',
  });

  const classes = useStyles();
  const [submitForm] = useMutation(SUBMIT_FORM);
  const [verifyVaspCode] = useMutation(VERIFY_VASP_CODE);

  const timer = React.useRef();
  const companyIDRef = useRef();
  const goLiveDtLabelRef = useRef();
  let countryInputRef;

  const isEditable = (operation) => operation === Operations.CREATE;

  const onSubmit = async (data) => {
    const submitData = _.assignWith(vaspDetail, data, (objValue, srcValue) =>
      !srcValue && srcValue !== false && srcValue !== 0 ? objValue : srcValue,
    );
    // go_live_dt is nullable, so it is able to modified to null
    submitData.go_live_dt = data.go_live_dt;

    if (regType === VaspRegisterType.EmailProtocol) {
      const { raw_assets: rawAssets, raw_receivers: receivers } = submitData;
      if (!receivers || receivers.length === 0) {
        setFormError(`raw_receivers`, {
          type: 'empty',
        });
        return;
      }

      const appendIndexAssets = rawAssets.map((asset, i) => ({ ...asset, index: i }));
      const currencyIDGroups = _.groupBy(appendIndexAssets, (asset) => asset.currency.currency_id);
      const assetGroups = Object.values(currencyIDGroups);

      let isValid = true;
      assetGroups.forEach((assets) => {
        const addressExists = {};
        assets.forEach((asset) => {
          const extraInfos = (asset.raw_extra_info || []).map((e) => `${e.key}=${e.value}`);
          const extraInfoExpression = extraInfos.join('&');
          const fullAddress = asset.address + (extraInfoExpression && `?${extraInfoExpression}`);

          if (addressExists[fullAddress]) {
            // address already exists , should show an error which address is duplicated
            setFormError(`raw_assets[${asset.index}].address`, {
              type: 'duplicated',
            });
            // isValid = false;
            if (isValid) {
              isValid = false;
            }
          } else {
            addressExists[fullAddress] = true;
          }
        });
      });
      if (!isValid) {
        return;
      }
    }

    setCreateLoading(true);
    try {
      await submitForm({
        variables: {
          input: transferDataToSubmitArg(submitData),
        },
        context: {
          headers: {
            authorization: token,
          },
        },
      });

      window.onbeforeunload = null;
      setCreateLoading(false);
      setCreateSuccess(true);
      setInformDialogOpen(true);
    } catch (e) {
      setCreateLoading(false);
      setErrorDialogOpen(true);
      setError(parseGraphQLErrorMessage(e));
    }
  };

  const onError = (validateErrors) => {
    if (validateErrors.country_code) {
      countryInputRef.focus();
      return;
    }
    if (validateErrors.vasp_category) {
      scrollToRef(companyIDRef);
    }
    if (validateErrors.go_live_dt) {
      scrollToRef(goLiveDtLabelRef);
    }
  };

  useEffect(() => {
    window.onbeforeunload = (e) => {
      const dialogText =
        'This page is asking you to confirm that you want to leave - data you have entered may not be saved.';
      e.returnValue = dialogText;
      return dialogText;
    };

    return () => {
      clearTimeout(timer.current);
      window.onbeforeunload = null;
    };
  }, []);

  const renderFormFieldByType = () => {
    switch (regType) {
      case VaspRegisterType.Bridge:
        return (
          <Bridge
            goLiveDefaultValue={vaspDetail.go_live_dt}
            goLiveForwardedRef={goLiveDtLabelRef}
          />
        );
      case VaspRegisterType.Hub:
        return (
          <div>
            <Hub goLiveDefaultValue={vaspDetail.go_live_dt} goLiveForwardedRef={goLiveDtLabelRef} />
            <PaidServices />
          </div>
        );

      case VaspRegisterType.EmailProtocol:
        return <EmailProtocol currencies={currencies} />;
      case VaspRegisterType.Gate:
        return <PaidServices />;

      default:
        return null;
    }
  };

  const triggerVaspCodeValidate = async () => {
    const input = getValues(['vasp_code_prefix', 'country_code', 'city_code']);

    input.country_code = _.get(input, 'country_code.country_code');

    if (!input.vasp_code_prefix || !input.country_code || !input.city_code) {
      return true;
    }

    const result = await verifyVaspCode({
      variables: {
        input,
      },
      context: {
        headers: {
          authorization: token,
        },
      },
    });

    setExistedVaspCodeError(_.get(result, 'data.verifyVaspCode', false));
  };

  useEffect(() => {
    formTrigger('vasp_code_prefix');
    formTrigger('country_code');
    formTrigger('country');
    formTrigger('city_code');
  }, [existedVaspCodeError]);

  return (
    <FormProvider {...hookFormProps}>
      <Layout>
        <div className={classes.content}>
          <header className={classes.header} role="banner">
            <div className={classes.headerContainer}>
              <Logo className={classes.logo} registrationType={regType} />
            </div>
          </header>
          <div id="main">
            <div className={classes.container}>
              <div className={classes.intro}>
                <h2 className={classes.introTitle}>Welcome to {serviceName} VASP Registration</h2>
              </div>
              <br />
              <div className={classes.container}>
                <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
                  <MarginVerticalSection>
                    <RequiredLabel htmlFor="email" value="Email Address" isRequired />
                    <ResizeTextField
                      id="vasp_email"
                      name="vasp_email"
                      value={vaspDetail.vasp_email}
                      label="Your valid email address."
                      disabled
                    />
                  </MarginVerticalSection>
                  <MarginVerticalSection>
                    <RequiredLabel htmlFor="company" value="Company Name" isRequired />
                    <ResizeTextField
                      id="vasp_name"
                      name="vasp_name"
                      value={vaspDetail.vasp_name}
                      label='Your registered company name. Example: "VASP LTD."'
                      disabled
                    />
                  </MarginVerticalSection>

                  <MarginVerticalSection>
                    <RequiredLabel htmlFor="vasp_code" value="VASP Code Name" isRequired />
                    <HookFormTextField
                      id="vasp_code_prefix"
                      name="vasp_code_prefix"
                      label='4 uppercase letters to represent your company name. Example: "Coinbase exchange" could be "CBSE",
                    "Sygna" could be "SYGN"'
                      placeholder="Input VASP code Name"
                      disabled={!isEditable(vaspDetail.operation)}
                      paddingLabel={20}
                      forwardedRef={register({
                        required: true,
                        pattern: /^[a-zA-Z]{4}$/,
                        minLength: 4,
                        maxLength: 4,
                        validate: () => existedVaspCodeError,
                      })}
                      onChange={(e) => {
                        setValue('vasp_code_prefix', e.target.value.toUpperCase());
                        triggerVaspCodeValidate();
                      }}
                      onBlur={() => triggerVaspCodeValidate()}
                      error={errors.vasp_code_prefix}
                      parseError={(e) => {
                        switch (_.get(e, ['type'], undefined)) {
                          case 'required':
                            return ErrorMessages.Required;
                          case 'validate':
                            return ErrorMessages.ExistedVaspCode;
                          case undefined:
                            return '';
                          default:
                            return ErrorMessages.InvalidUpperCaseLettersLength(4);
                        }
                      }}
                    />
                  </MarginVerticalSection>
                  <MarginVerticalSection>
                    <RequiredLabel htmlFor="country_code" value="Country Code" isRequired />
                    <Controller
                      render={({ onChange, ...props }) => (
                        <Autocomplete
                          {...props}
                          id="country_code"
                          options={countries}
                          getOptionLabel={(option) => {
                            if (!option) {
                              return '';
                            }
                            return `${option.country_code} (${option.country_name})`;
                          }}
                          disabled={!isEditable(vaspDetail.operation)}
                          renderInput={(params) => (
                            <ResizeTextField
                              {...params}
                              id="country"
                              name="country"
                              label="Country location of your company jurisdiction. Please follow ISO 3166-1 alpha-2
                            country code."
                              placeholder="Input country"
                              error={errors.country_code}
                              helperText={
                                errors.country_code
                                  ? errors.country_code.type === 'validate'
                                    ? ErrorMessages.ExistedVaspCode
                                    : ErrorMessages.Required
                                  : ''
                              }
                              inputRef={(input) => {
                                countryInputRef = input;
                              }}
                            />
                          )}
                          onChange={(__, v) => {
                            onChange(v);
                          }}
                          onBlur={() => triggerVaspCodeValidate()}
                        />
                      )}
                      onChange={([, country]) => {
                        setValue('country_code', country.country_code);
                      }}
                      name="country_code"
                      control={control}
                      rules={{
                        required: true,
                        validate: () => existedVaspCodeError,
                      }}
                      defaultValue={vaspDetail.country_code}
                    />
                  </MarginVerticalSection>
                  <MarginVerticalSection>
                    <RequiredLabel htmlFor="city_code" value="City Code" isRequired />
                    <HookFormTextField
                      id="city_code"
                      name="city_code"
                      label='2 letters city abbreviation. Example: "NY"'
                      placeholder="Input city code"
                      disabled={!isEditable(vaspDetail.operation)}
                      forwardedRef={register({
                        required: true,
                        pattern: /[A-Za-z0-9]{2}/,
                        minLength: 2,
                        maxLength: 2,
                        validate: () => existedVaspCodeError,
                      })}
                      onChange={(e) => {
                        setValue('city_code', e.target.value.toUpperCase());
                      }}
                      onBlur={() => triggerVaspCodeValidate()}
                      error={errors.city_code}
                      parseError={(e) => {
                        switch (_.get(e, ['type'], undefined)) {
                          case 'required':
                            return ErrorMessages.Required;
                          case 'validate':
                            return ErrorMessages.ExistedVaspCode;
                          case undefined:
                            return '';
                          default:
                            return ErrorMessages.InvalidUpperCaseLettersOrNumbersLength(2);
                        }
                      }}
                    />
                  </MarginVerticalSection>

                  <MarginVerticalSection>
                    <RequiredLabel
                      htmlFor="company_id"
                      value="Company ID"
                      forwardedRef={companyIDRef}
                    />
                    <HookFormTextField
                      id="company_id"
                      name="company_id"
                      fullWidth
                      label="Company registration code under local jurisdiction."
                      placeholder="Input company ID"
                      forwardedRef={register()}
                      onChange={(e) => setValue('company_id', e.target.value)}
                    />
                  </MarginVerticalSection>

                  <MarginVerticalSection>
                    <RequiredLabel htmlFor="vasp_category" value="VASP Category" isRequired />
                    <Controller
                      render={({ ...props }) => (
                        <div className={classes.root}>
                          <FormControl
                            className={classes.formControl}
                            error={!!errors.vasp_category}
                          >
                            <Select
                              {...props}
                              fullWidth
                              className={classes.normalFontSize}
                              id="vasp_category"
                            >
                              <MenuItem value="Virtual/Fiat">Virtual/Fiat</MenuItem>
                              <MenuItem value="Virtual/Virtual">Virtual/Virtual</MenuItem>
                              <MenuItem value="Transfer">Transfer</MenuItem>
                              <MenuItem value="Custodian">Custodian</MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                            </Select>
                            <FormHelperText id="vasp_category-helper-text">
                              {errors.vasp_category && ErrorMessages.Required}
                            </FormHelperText>
                          </FormControl>
                        </div>
                      )}
                      name="vasp_category"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      defaultValue={vaspDetail.vasp_category}
                    />
                  </MarginVerticalSection>

                  <MarginVerticalSection>
                    <RequiredLabel
                      htmlFor="estimated_customer_number"
                      value="Estimated Number Of Customers"
                      isRequired
                    />
                    <HookFormTextField
                      id="estimated_customer_number"
                      name="estimated_customer_number"
                      fullWidth
                      type="number"
                      placeholder="Input estimated number of customers"
                      forwardedRef={register('estimated_customer_number', {
                        required: true,
                        validate: (v) => v > 0,
                      })}
                      parseError={(e) => {
                        const type = _.get(e, ['type'], undefined);
                        if (!type) {
                          return '';
                        }
                        if (type === 'required') {
                          return ErrorMessages.Required;
                        }
                        return ErrorMessages.InvalidEstimateValue;
                      }}
                      error={errors.estimated_customer_number}
                      onChange={(e) => setValue('estimated_customer_number', e.target.value)}
                    />
                  </MarginVerticalSection>

                  <MarginVerticalSection>
                    <RequiredLabel
                      htmlFor="estimated_annual_transaction_volume"
                      value="Estimated Annual Transaction Volume (in US$)"
                      isRequired
                    />
                    <HookFormTextField
                      id="estimated_annual_transaction_volume"
                      name="estimated_annual_transaction_volume"
                      fullWidth
                      type="number"
                      inputProps={{ step: 'any' }}
                      placeholder="Input estimated annual transaction volume (in US$)"
                      forwardedRef={register('estimated_annual_transaction_volume', {
                        required: true,
                        validate: (v) => v > 0,
                      })}
                      parseError={(e) => {
                        const type = _.get(e, ['type'], undefined);
                        if (!type) {
                          return '';
                        }
                        if (type === 'required') {
                          return ErrorMessages.Required;
                        }
                        return ErrorMessages.InvalidEstimateValue;
                      }}
                      error={errors.estimated_annual_transaction_volume}
                      onChange={(e) =>
                        setValue('estimated_annual_transaction_volume', e.target.value)
                      }
                    />
                  </MarginVerticalSection>

                  <MarginVerticalSection>
                    <RequiredLabel
                      htmlFor="estimated_annual_travel_rule_data_transfer_number"
                      value="Estimated Annual Number Of Travel Rule Data Transfers"
                      isRequired
                    />
                    <HookFormTextField
                      id="estimated_annual_travel_rule_data_transfer_number"
                      name="estimated_annual_travel_rule_data_transfer_number"
                      fullWidth
                      type="number"
                      placeholder="Input estimated annual number of Travel Rule data transfers"
                      forwardedRef={register('estimated_annual_travel_rule_data_transfer_number', {
                        required: true,
                        validate: (v) => v > 0,
                      })}
                      parseError={(e) => {
                        const type = _.get(e, ['type'], undefined);
                        if (!type) {
                          return '';
                        }
                        if (type === 'required') {
                          return ErrorMessages.Required;
                        }
                        return ErrorMessages.InvalidEstimateValue;
                      }}
                      error={errors.estimated_annual_travel_rule_data_transfer_number}
                      onChange={(e) =>
                        setValue(
                          'estimated_annual_travel_rule_data_transfer_number',
                          e.target.value,
                        )
                      }
                    />
                  </MarginVerticalSection>

                  {renderFormFieldByType()}

                  <div className={classes.ctaBlock}>
                    <p className={classes.ctaBlockText}>
                      If you have any questions with the information in this email, please let us
                      know at&nbsp;
                      <a
                        href="mailto:services@sygna.io"
                        style={{
                          color: '#0091ff',
                          transition: '0.5s',
                        }}
                      >
                        services@sygna.io
                      </a>
                      &nbsp;or visit our website.
                    </p>
                    <div className="cta" style={{ textAlign: 'center' }}>
                      <LoadingButton
                        id="submit"
                        loading={createLoading}
                        done={{ get: createSuccess, set: setCreateSuccess }}
                        buttonType="submit"
                        buttonText="Submit"
                        buttonClassName={classes.ctaBtn}
                        buttonProgressClassName={classes.buttonProgress}
                        disabled={
                          regType === VaspRegisterType.EmailProtocol &&
                          (!currencies || currencies.length === 0)
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ErrorDialog
          id="error_dialog"
          open={{ get: errorDialogOpen, set: setErrorDialogOpen }}
          message={error}
        />
        <CustomDialog
          id="confirm_dialog"
          open={{ get: informDialogOpen, set: setInformDialogOpen }}
          title="Inform"
          message="submit success"
          positiveButtonText="ok"
          onClose={() => {
            setInformDialogOpen(false);
            window.location.replace('https://www.sygna.io/');
          }}
          timeout={5000}
        />
      </Layout>
    </FormProvider>
  );
}

Registration.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
};
