import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import _ from 'lodash';
import { MarginVerticalSection } from '../styled';
import { RequiredLabel, HookFormTextField } from '../common';
import { ErrorMessages } from '../../constants';
import { isValidHostUrl } from '../../utils/utils';
import FixedIP from './fixedIP';
import GoLive from './goLive';
import PublicKey from './publicKey';

export default function Hub({ goLiveDefaultValue, goLiveForwardedRef }) {
  const { setValue, errors, register } = useFormContext();
  return (
    <>
      <MarginVerticalSection>
        <RequiredLabel
          htmlFor="vasp_api"
          value="Beneficiary receive notification host URL"
          isRequired
        />
        <HookFormTextField
          id="callback_host_url"
          name="callback_host_url"
          label={
            <>
              API endpoint for beneficiary VASP to receive all notifications from Sygna Bridge API,
              please only input hostname part, ex: google.com
            </>
          }
          placeholder="Input beneficiary receive notification host URL"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ fontSize: 16 }}>
                https://
              </InputAdornment>
            ),
          }}
          paddingLabel={40}
          onChange={(e) => setValue('callback_host_url', e.target.value)}
          forwardedRef={register({
            required: true,
            validate: isValidHostUrl,
          })}
          error={errors.callback_host_url}
          parseError={(e) => {
            const type = _.get(e, ['type'], undefined);
            if (!type) {
              return '';
            }
            if (type === 'required') {
              return ErrorMessages.Required;
            }
            return ErrorMessages.InvalidURLFormat;
          }}
        />
      </MarginVerticalSection>
      <FixedIP />
      <GoLive defaultValue={goLiveDefaultValue} forwardedRef={goLiveForwardedRef} />
      <PublicKey />
    </>
  );
}

Hub.propTypes = {
  goLiveDefaultValue: PropTypes.string.isRequired,
  goLiveForwardedRef: PropTypes.shape(React.createRef()).isRequired,
};
